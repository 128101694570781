<template>
  <div class="otpnotyouremail">
    <div class="link-div-top">
      <router-link to="./top" class="page_link">Home</router-link> > <router-link to="./OTPPage" class="page_link">OTP Page</router-link> > Not your MailAddress
    </div>
    <br /><br /><br />
    <div class="qa">
      <h1>Q. What should I do if a different user's email address is displayed<br />
      on the authentication code input screen and the OTP email hasn't arrived?</h1>
      <div class="eximg">
        <div>
          <img alt="otp_not_your_email_en" src="../../assets/otp_not_your_email_en.png">
        </div>
      </div>
      <h3>Answer</h3>
      <p class="answer">
        An authentication code was sent to another user's email address when you logged in.<br />
        There is a possibility that the credentials of a previous login user who used the same computer are still present.<br />
        To successfully send the authentication code to your own email address, please clear your browser's cache data and log in again.<br />
        manual:<a href="/ClearBrowsingData.pdf" target="_blank" rel="noopener noreferrer">ClearBrowsingData.pdf</a><br /><br />
      </p>
    </div>
    <br /><br />
    <div class="link-div-bottom">
      <router-link to="./InvalidAuthenticationCode"><img id="back" src="/arrow_back.png" v-on:mouseover="imageBackChange" v-on:mouseleave="imageBackReturn" /></router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
  </div>
</template>
<style>
.qa {
  min-width:800px;
  width:auto !important;
  width:800px;
  border-style:solid;
  border-width:1px;
  border-color:gray;
  border-radius:3px;
  padding:20px;
}
.answer {
  font-size:20px;
}

.link-div-top {
  text-align:left;
}

.link-div-bottom {
  text-align:center;
}

.eximg {
  display: flex;
  flex-direction: row;
}

</style>
<script>
export default {
  methods: {
    imageForwardChange: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward_on.png'
    },
    imageForwardReturn: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward.png'
    },
    imageBackChange: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back_on.png'
    },
    imageBackReturn: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back.png'
    }
  }
}
</script>
